<template>
  <div class="page-wrap">
    <div class="title">生物标志物</div>
    <div class="icon-wrap" ref="wrap" @click="handleClick">
      <div style="display: flex;">
        <div
          class="icon-item"
          v-for="(item, index) in fistIcon"
          :key="index"
          :style="{
            width: wrapWidth * 0.09 + 'px',
            height: wrapWidth * 0.09 * 1.175 + 'px'
          }"
        >
          <img v-if="item === 1" src="~@/assets/imgs/single.png" alt="" />
          <img
            v-if="iconList[12] && index === 1"
            :id="iconList[12].id"
            :src="getUrl(iconList[12].logo_stream, iconList[12].logo)"
            alt=""
          />
          <img
            v-if="iconList[4] && index === 4"
            :id="iconList[4].id"
            :src="getUrl(iconList[4].logo_stream, iconList[4].logo)"
            alt=""
          />
        </div>
      </div>
      <div
        :style="{
          whiteSpace: 'nowrap',
          marginLeft: -((wrapWidth * 0.09) / 2) + 'px',
          marginTop: -((wrapWidth * 0.09 * 1.175) / 4) + 'px'
        }"
      >
        <div
          class="icon-item"
          v-for="(item, index) in secondIcon"
          :key="index"
          :style="{
            width: wrapWidth * 0.09 + 'px',
            height: wrapWidth * 0.09 * 1.175 + 'px'
          }"
        >
          <img v-if="item === 1" src="~@/assets/imgs/single.png" alt="" />
          <img
            v-if="iconList[0] && index === 5"
            :id="iconList[0].id"
            :src="getUrl(iconList[0].logo_stream, iconList[0].logo)"
            alt=""
          />
          <img
            v-if="iconList[2] && index === 4"
            :id="iconList[2].id"
            :src="getUrl(iconList[2].logo_stream, iconList[2].logo)"
            alt=""
          />
          <img
            v-if="iconList[8] && index === 3"
            :id="iconList[8].id"
            :src="getUrl(iconList[8].logo_stream, iconList[8].logo)"
            alt=""
          />
          <img
            v-if="iconList[10] && index === 2"
            :id="iconList[10].id"
            :src="getUrl(iconList[10].logo_stream, iconList[10].logo)"
            alt=""
          />
          <img
            v-if="iconList[5] && index === 7"
            :id="iconList[5].id"
            :src="getUrl(iconList[5].logo_stream, iconList[5].logo)"
            alt=""
          />
          <img
            v-if="iconList[7] && index === 8"
            :id="iconList[7].id"
            :src="getUrl(iconList[7].logo_stream, iconList[7].logo)"
            alt=""
          />
          <img
            v-if="iconList[9] && index === 9"
            :id="iconList[9].id"
            :src="getUrl(iconList[9].logo_stream, iconList[9].logo)"
            alt=""
          />
        </div>
      </div>
      <div
        :style="{
          whiteSpace: 'nowrap',
          marginTop: -((wrapWidth * 0.09 * 1.175) / 4) + 'px'
        }"
      >
        <div
          class="icon-item"
          v-for="(item, index) in thirdIcon"
          :key="index"
          :style="{
            width: wrapWidth * 0.09 + 'px',
            height: wrapWidth * 0.09 * 1.175 + 'px'
          }"
        >
          <img v-if="item === 1" src="~@/assets/imgs/single.png" alt="" />
          <img
            v-if="iconList[6] && index === 3"
            :id="iconList[6].id"
            :src="getUrl(iconList[6].logo_stream, iconList[6].logo)"
            alt=""
          />
          <img
            v-if="iconList[1] && index === 5"
            :id="iconList[1].id"
            :src="getUrl(iconList[1].logo_stream, iconList[1].logo)"
            alt=""
          />
          <img
            v-if="iconList[3] && index === 6"
            :id="iconList[3].id"
            :src="getUrl(iconList[3].logo_stream, iconList[3].logo)"
            alt=""
          />
          <img
            v-if="iconList[11] && index === 9"
            :id="iconList[11].id"
            :src="getUrl(iconList[11].logo_stream, iconList[11].logo)"
            alt=""
          />
        </div>
      </div>
      <div
        :style="{
          whiteSpace: 'nowrap',
          marginLeft: -((wrapWidth * 0.09) / 2) + 'px',
          marginTop: -((wrapWidth * 0.09 * 1.175) / 4) + 'px'
        }"
      >
        <div
          class="icon-item"
          v-for="(item, index) in fourthIcon"
          :key="index"
          :style="{
            width: wrapWidth * 0.09 + 'px',
            height: wrapWidth * 0.09 * 1.175 + 'px'
          }"
        >
          <img v-if="item === 1" src="~@/assets/imgs/single.png" alt="" />
        </div>
      </div>
      <div class="logo"><img src="~@/assets/imgs/bio-logo.png" alt="" /></div>
      <div class="pre icon-page" v-if="biomarkerTotal > 13" @click="handlePre">
        <Icon size="50" color="#fff" type="ios-arrow-back" />
      </div>
      <div class="next icon-page" v-if="biomarkerTotal > 13" @click="handleNext">
        <Icon size="50" color="#fff" type="ios-arrow-forward" />
      </div>
    </div>
    <div class="title">检索</div>
    <div class="card-wrap">
      <div style=" font-size: 18px;font-weight: bold;">
        已收藏检索式
      </div>

      <div>
        <div v-for="(item, index) in formulas" :key="index">
          <CollectCard
            :formula="item.search_content"
            :time="item.created_at"
            @delete="deleteHandle(item)"
            @formula="formulaHandle(item)"
          ></CollectCard>
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <Page :total="total" :current="page" @on-change="pageChange"></Page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteZhiKuRetrieval, getBiomarkers, getZhiKuRetrievalList } from '@/api/zhiku'
import { thinkTankTracking } from '@/api/tracking/'
import CollectCard from './components/CollectCard'
import dayjs from 'dayjs'
import { Message, Page, Icon } from 'view-design'
const base64Map = new Map([
  ['png', 'data:image/png;base64,'],
  ['jpg', 'data:image/jpg;base64,'],
  ['jpeg', 'data:image/jpeg;base64,'],
  ['gif', 'data:image/gif;base64,'],
  ['bmp', 'data:image/bmp;base64,']
])
export default {
  components: {
    CollectCard,
    Page,
    Icon
  },
  data() {
    return {
      wrapWidth: 0,
      wrapHeight: 0,
      fistIcon: [0, 1, 0, 1, 1, 1, 0, 1, 1, 0, 0],
      secondIcon: [1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1],
      thirdIcon: [1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0],
      fourthIcon: [0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 0],
      iconList: [],
      page: 1,
      page_size: 5,
      formulas: [],
      total: 0,
      biomarkerPage: 1,
      biomarkerPage_size: 13,
      biomarkerTotal: 0
    }
  },
  mounted() {
    this.wrapWidth = this.$refs.wrap.offsetWidth
    this.$refs.wrap.style.height = this.$refs.wrap.offsetWidth / (222 / 71) + 'px'
    this.wrapHeight = this.$refs.wrap.offsetHeight
    this.handleResize = () => {
      this.wrapWidth = this.$refs.wrap.offsetWidth
      this.$refs.wrap.style.height = this.$refs.wrap.offsetWidth / (222 / 71) + 'px'
      this.wrapHeight = this.$refs.wrap.offsetHeight
    }
    window.addEventListener('resize', this.handleResize)
    this.fetchData()
    this.fetchRetrieval()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  // watch: {
  //   iconList: {
  //     handler(val) {
  //       const mappings = [
  //         { array: this.fistIcon, indexes: [1, 4] },
  //         { array: this.secondIcon, indexes: [5, 4, 3, 2, 7, 8, 9] },
  //         { array: this.thirdIcon, indexes: [3, 5, 6, 9] }
  //       ]

  //       mappings.forEach(({ array, indexes }) => {
  //         indexes.forEach((index, targetIndex) => {
  //           console.log(val[index], 'watch')
  //           array[targetIndex] = val[index] ? 0 : 1
  //         })
  //       })
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    getUrl(stm, url) {
      if (url) {
        return `${base64Map.get(url.split('.').pop())}${stm}`
      } else {
        return null
      }
    },
    fetchData() {
      getBiomarkers().then(res => {
        this.biomarkerTotal = res.data.length
        const startIndex = (this.biomarkerPage - 1) * this.biomarkerPage_size
        const endIndex = startIndex + this.biomarkerPage_size
        const dataList = res.data.slice(startIndex, endIndex)
        this.iconList = dataList.map(item => ({
          ...item,
          icon: item.logo
        }))
      })
    },
    fetchRetrieval() {
      getZhiKuRetrievalList({
        page: this.page,
        page_size: this.page_size
      }).then(res => {
        if (res.code === 200) {
          this.formulas = res.data.map(item => ({
            ...item,
            created_at: dayjs(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
            search_content: JSON.parse(item.search_content)
          }))
          this.total = res.page.count
        }
      })
    },
    handlePre() {
      if (this.biomarkerPage === 1) {
        Message.warning('已经是第一页了')
        return
      }
      this.biomarkerPage--
      this.fetchData()
    },
    handleNext() {
      if (this.biomarkerPage === Math.ceil(this.biomarkerTotal / this.biomarkerPage_size)) {
        Message.warning('已经是最后一页了')
        return
      }
      this.biomarkerPage++
      this.fetchData()
    },
    pageChange(page) {
      this.page = page
      this.fetchRetrieval()
    },
    deleteHandle(item) {
      deleteZhiKuRetrieval(item.id).then(res => {
        if (res.code === 200) {
          Message.info('删除成功')
          this.page = 1
          this.fetchRetrieval()
        } else {
          Message.error(res.message)
        }
      })
    },
    formulaHandle(item) {
      this.$router.push(
        `/zhiku/lists?id=${item.search_content.filter.id}&name=${item.search_content.filter.name}&filter=${item.id}`
      )
    },
    handleClick(e) {
      if (e.target.id) {
        thinkTankTracking({
          data_type: '生物标志物',
          related_key: 'biomarker',
          biomarker: e.target.id
        })
        this.$router.push(
          `/zhiku/lists?id=${e.target.id}&name=${this.iconList.find(item => item.id === Number(e.target.id)).name}`
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrap {
  padding-top: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #404e69;
    margin-bottom: 15px;
  }
  .icon-wrap {
    box-shadow: 4px 4px 20px 0px rgba(22, 72, 131, 0.1);
    border-radius: 12px;
    border: 2px solid #ffffff;
    background: url('~@/assets/imgs/bg.png') no-repeat center;
    background-size: cover;
    width: 100%;
    // padding-bottom: calc(100% / (222 / 71));
    margin-bottom: 32px;
    position: relative;
    overflow: hidden;
    padding-top: 20px;

    .logo {
      width: 18.5%;
      position: absolute;
      left: 30px;
      bottom: 20px;

      & > img {
        width: 100%;
      }
    }

    .icon-item {
      display: inline-block;
      position: relative;
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        & > img {
          opacity: 0.5;
        }
      }
    }
  }

  .card-wrap {
    box-sizing: border-box;
    height: 278px;
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 15px;
    padding: 20px;
  }

  .icon-page {
    width: 73px;
    height: 73px;
    border-radius: 50%;
    background: rgba(14, 15, 92, 0.15);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pre {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
  }

  .next {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
</style>
