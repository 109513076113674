var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"title"},[_vm._v("生物标志物")]),_c('div',{ref:"wrap",staticClass:"icon-wrap",on:{"click":_vm.handleClick}},[_c('div',{staticStyle:{"display":"flex"}},_vm._l((_vm.fistIcon),function(item,index){return _c('div',{key:index,staticClass:"icon-item",style:({
          width: _vm.wrapWidth * 0.09 + 'px',
          height: _vm.wrapWidth * 0.09 * 1.175 + 'px'
        })},[(item === 1)?_c('img',{attrs:{"src":require("@/assets/imgs/single.png"),"alt":""}}):_vm._e(),(_vm.iconList[12] && index === 1)?_c('img',{attrs:{"id":_vm.iconList[12].id,"src":_vm.getUrl(_vm.iconList[12].logo_stream, _vm.iconList[12].logo),"alt":""}}):_vm._e(),(_vm.iconList[4] && index === 4)?_c('img',{attrs:{"id":_vm.iconList[4].id,"src":_vm.getUrl(_vm.iconList[4].logo_stream, _vm.iconList[4].logo),"alt":""}}):_vm._e()])}),0),_c('div',{style:({
        whiteSpace: 'nowrap',
        marginLeft: -((_vm.wrapWidth * 0.09) / 2) + 'px',
        marginTop: -((_vm.wrapWidth * 0.09 * 1.175) / 4) + 'px'
      })},_vm._l((_vm.secondIcon),function(item,index){return _c('div',{key:index,staticClass:"icon-item",style:({
          width: _vm.wrapWidth * 0.09 + 'px',
          height: _vm.wrapWidth * 0.09 * 1.175 + 'px'
        })},[(item === 1)?_c('img',{attrs:{"src":require("@/assets/imgs/single.png"),"alt":""}}):_vm._e(),(_vm.iconList[0] && index === 5)?_c('img',{attrs:{"id":_vm.iconList[0].id,"src":_vm.getUrl(_vm.iconList[0].logo_stream, _vm.iconList[0].logo),"alt":""}}):_vm._e(),(_vm.iconList[2] && index === 4)?_c('img',{attrs:{"id":_vm.iconList[2].id,"src":_vm.getUrl(_vm.iconList[2].logo_stream, _vm.iconList[2].logo),"alt":""}}):_vm._e(),(_vm.iconList[8] && index === 3)?_c('img',{attrs:{"id":_vm.iconList[8].id,"src":_vm.getUrl(_vm.iconList[8].logo_stream, _vm.iconList[8].logo),"alt":""}}):_vm._e(),(_vm.iconList[10] && index === 2)?_c('img',{attrs:{"id":_vm.iconList[10].id,"src":_vm.getUrl(_vm.iconList[10].logo_stream, _vm.iconList[10].logo),"alt":""}}):_vm._e(),(_vm.iconList[5] && index === 7)?_c('img',{attrs:{"id":_vm.iconList[5].id,"src":_vm.getUrl(_vm.iconList[5].logo_stream, _vm.iconList[5].logo),"alt":""}}):_vm._e(),(_vm.iconList[7] && index === 8)?_c('img',{attrs:{"id":_vm.iconList[7].id,"src":_vm.getUrl(_vm.iconList[7].logo_stream, _vm.iconList[7].logo),"alt":""}}):_vm._e(),(_vm.iconList[9] && index === 9)?_c('img',{attrs:{"id":_vm.iconList[9].id,"src":_vm.getUrl(_vm.iconList[9].logo_stream, _vm.iconList[9].logo),"alt":""}}):_vm._e()])}),0),_c('div',{style:({
        whiteSpace: 'nowrap',
        marginTop: -((_vm.wrapWidth * 0.09 * 1.175) / 4) + 'px'
      })},_vm._l((_vm.thirdIcon),function(item,index){return _c('div',{key:index,staticClass:"icon-item",style:({
          width: _vm.wrapWidth * 0.09 + 'px',
          height: _vm.wrapWidth * 0.09 * 1.175 + 'px'
        })},[(item === 1)?_c('img',{attrs:{"src":require("@/assets/imgs/single.png"),"alt":""}}):_vm._e(),(_vm.iconList[6] && index === 3)?_c('img',{attrs:{"id":_vm.iconList[6].id,"src":_vm.getUrl(_vm.iconList[6].logo_stream, _vm.iconList[6].logo),"alt":""}}):_vm._e(),(_vm.iconList[1] && index === 5)?_c('img',{attrs:{"id":_vm.iconList[1].id,"src":_vm.getUrl(_vm.iconList[1].logo_stream, _vm.iconList[1].logo),"alt":""}}):_vm._e(),(_vm.iconList[3] && index === 6)?_c('img',{attrs:{"id":_vm.iconList[3].id,"src":_vm.getUrl(_vm.iconList[3].logo_stream, _vm.iconList[3].logo),"alt":""}}):_vm._e(),(_vm.iconList[11] && index === 9)?_c('img',{attrs:{"id":_vm.iconList[11].id,"src":_vm.getUrl(_vm.iconList[11].logo_stream, _vm.iconList[11].logo),"alt":""}}):_vm._e()])}),0),_c('div',{style:({
        whiteSpace: 'nowrap',
        marginLeft: -((_vm.wrapWidth * 0.09) / 2) + 'px',
        marginTop: -((_vm.wrapWidth * 0.09 * 1.175) / 4) + 'px'
      })},_vm._l((_vm.fourthIcon),function(item,index){return _c('div',{key:index,staticClass:"icon-item",style:({
          width: _vm.wrapWidth * 0.09 + 'px',
          height: _vm.wrapWidth * 0.09 * 1.175 + 'px'
        })},[(item === 1)?_c('img',{attrs:{"src":require("@/assets/imgs/single.png"),"alt":""}}):_vm._e()])}),0),_vm._m(0),(_vm.biomarkerTotal > 13)?_c('div',{staticClass:"pre icon-page",on:{"click":_vm.handlePre}},[_c('Icon',{attrs:{"size":"50","color":"#fff","type":"ios-arrow-back"}})],1):_vm._e(),(_vm.biomarkerTotal > 13)?_c('div',{staticClass:"next icon-page",on:{"click":_vm.handleNext}},[_c('Icon',{attrs:{"size":"50","color":"#fff","type":"ios-arrow-forward"}})],1):_vm._e()]),_c('div',{staticClass:"title"},[_vm._v("检索")]),_c('div',{staticClass:"card-wrap"},[_c('div',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v(" 已收藏检索式 ")]),_c('div',[_vm._l((_vm.formulas),function(item,index){return _c('div',{key:index},[_c('CollectCard',{attrs:{"formula":item.search_content,"time":item.created_at},on:{"delete":function($event){return _vm.deleteHandle(item)},"formula":function($event){return _vm.formulaHandle(item)}}})],1)}),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"flex-end"}},[_c('Page',{attrs:{"total":_vm.total,"current":_vm.page},on:{"on-change":_vm.pageChange}})],1)],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/imgs/bio-logo.png"),"alt":""}})])}]

export { render, staticRenderFns }