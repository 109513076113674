<template>
  <div class="formula-lists">
    <div class="formula">
      <div class="left">
        <div class="time">{{time}}</div>
        <div class="labels" @click="handleFormula">
          <div class="label normal">
            {{formula.filter.name}}
            <span v-for="(label, index) in formula.formulas.disease" :key="index + 'd'">
              <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.biomarkers" :key="index + 'b'">
              <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.drug" :key="index + 'dr'">
              <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.medical" :key="index + 'me'">
            <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.research" :key="index + 'r'"
            >
              <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.reagent" :key="index + 're'">
              <span>-</span>{{label}}
            </span>
            <span v-for="(label, index) in formula.formulas.instrument" :key="index + 'i'">
              <span>-</span>{{label}}
            </span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="delete" @click="isDelete">
          <i class="iconfont iconshanchu"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollectCard',

  props: {
    time: {
      type: String,
      default: ''
    },
    formula: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    isLogical() {
      return function (label) {
        return label.toLowerCase() === 'or' || label.toLowerCase() === 'and'
      }
    }
  },

  methods: {
    handleFormula() {
      this.$emit('formula')
    },
    isDelete() {
      this.$emit('delete')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>
